$('.projects-filter .dropdown-menu li a').click(function(e) {
    var newHeading = $(this).text(),
            $list = $('.projects-list'),
            $projects = $list.children('li'),
            $projectsToShow = [];
    $('.projects-filter .dropdown-toggle').html(newHeading + "<span class=\"caret\"></span>");

    $projects.fadeOut(200);
    $projects.each(function(i, v) {
        var $proj = $(v);
        if ($proj.data('type') === newHeading || newHeading === 'All projects') {
            $projectsToShow.push($proj);
        }
    });
    $($projectsToShow).each(function(i) {
        $(this).delay(200 * i).fadeIn(200);
    });
});